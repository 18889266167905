interface Callback<Params extends unknown[]> {
  (...args: Params): void;
}

export const callAll =
  <Params extends unknown[]>(...fns: Array<Callback<Params> | undefined>) =>
  (...args: Params): void =>
    fns.forEach((fn) => typeof fn === 'function' && fn(...args));


export const formatBytes = (bytes: number, decimals: number): string => {
  if(bytes == 0) return '0 Bytes';
  const k = 1024,
      dm: number = decimals || 2,
      sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i: number = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const createSearchHashKey = (
  date: string,
  location: number,
  activity: number,
  time: string,
  timeEnd: string,
  photographer?: { label: string, value: number},
  tags?: {label: string, value: number}[]
): string => {

  let searchHash = `${location}-${activity}-${date}-${time}-${timeEnd}`;

  if (photographer) {
    searchHash += `-p${photographer.value}`
  }

  if (tags) {
    searchHash += `-${tags.map((tag) => tag.value).sort().join('-')}`
  }

  return searchHash;
}
